import React from "react";
import Project from "../Project/Project";
import pallmall from "../../assets/pallmall.jpg";
import datascience from "../../assets/datascience.jpg";
import hotel from "../../assets/hotel.jpg";
const projectList = [
  {
    id: "item1",
    title: "Ecommerce System",
    img: pallmall,
    description:
      "This is a product I created with the purpose of helping users across Vietnam to buy and sell online conveniently. This system has basic features such as helping users buy and pay online, become a supplier and some other attractive features... Please visit!",
    technologies: ["WordPress", "MySql", "PHP", "Python"],
    github: "https://github.com/cityiscoding/Ecomerce-website-wp",
    address: "https://pallmall.shop",
    isProcessing: false,
    isProcessing: false,
    account: [
      {
        username: "User Name: userguest",
        password: "PassWord: userguest@123",
      },
    ],
  },
  {
    id: "item2",
    title: "Data-Science",
    img: datascience,
    description:
      "This is a repository that helps provide a set of tools that allow you to explore and apply different data analysis methods to extract important information from different data sources.",
    technologies: ["Python", "Jupyter Notebook", "Data Science"],
    github: "https://github.com/cityiscoding/Data-science",
    address: "https://github.com/cityiscoding/Data-science",

  },
  {
    id: "item3",
    title: "Hotel Management Software",
    img: hotel,
    description: "This is a software that helps users manage hotels with basic functions such as: login, registration, adding, editing, deleting rooms and users, and some other good features. Please visit!",
    technologies: ["WinForm", "C#", "Sql Server", "Entity Framework"],
    github: "https://github.com/cityiscoding/Software_WinForm",
    address: "https://github.com/cityiscoding/Software_WinForm  ",
    isProcessing: false,
  },
];

export default function ProjectList() {
  return (
    <div
      data-aos="fade-up"
      id="projects"
      className="bg-slate-200 py-16  relative"
    >
      <span className="relative flex text-black font-bold w-fit m-auto text-2xl  md:text-4xl after:w-full after:bg-black after:h-0.5 after:absolute after:mt-8 after:md:mt-12 ">
        Projects
      </span>
      <div className="carousel m-auto">
        <Project projectList={projectList} />
      </div>
      <div className="flex justify-center w-full py-2 gap-2">
        {projectList.map((_, index) => (
          <a href={`#item${++index}`} className="btn btn-xs">
            {index}
          </a>
        ))}
      </div>
    </div>
  );
}
